import Contact from './Contact';
import Projects from './Projects';
import Home from './Home';
import Menu from './Menu';
import Skills from './Skills';
import './style.css';

import { Analytics } from '@vercel/analytics/react';


 export default function Portfolio() {
    return (
        <>  
            <Menu />
            <Home />
            <Skills />
            <Projects />
            <Contact />
            <Analytics />
        </>
    );
}

