import html_logo from '../../../assets/icons8-html-5.svg';
import css_logo from '../../../assets/icons8-css3.svg';
import js_logo from '../../../assets/icons8-javascript.svg';
import react_logo from '../../../assets/icons8-react-native.svg';
import tailwind_logo from '../../../assets/icons8-tailwind-css.svg';
import nodejs_logo from '../../../assets/icons8-node-js.svg';
import postgresql_logo from '../../../assets/icons8-postgresql.svg';

function Skills () {
    return (
        <div id="skills" className="h-max p-20">
            <div className="text-fourth text-6xl">
                <h1 className="flex justify-center mb-8">
                    <span className="text-5xl lg:text-7xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-fourth to-blue-500">Skills</span>
                </h1>
            </div>
        <div className="flex justify-center space-x-20">
            <div className="flex-col space-y-3 ">
                <h2 className="text-fourth text-4xl font-semibold"> Frontend</h2>
                    <p className="text-white"><img className="inline-block mr-1" src={html_logo} alt="html-logo"></img>HTML</p>
                    <p className="text-white"><img className="inline-block mr-1" src={css_logo} alt="css-logo"></img>CSS</p>
                    <p className="text-white"><img className="inline-block mr-1" src={js_logo} alt="js-logo"></img>JavaScript</p>
                    <p className="text-white"><img className="inline-block mr-1" src={react_logo} alt="react-logo"></img>React</p>
                    <p className="text-white"><img className="inline-block mr-1" src={tailwind_logo} alt="tailwind-logo"></img>Tailwind CSS</p>
            </div>
            <div className="flex-col space-y-3">
                <h2 className="text-fourth text-4xl font-semibold"> Backend</h2>
                <p className="text-white"><img className="inline-block mr-1" src={nodejs_logo} alt="nodejs-logo"></img>Node.js</p>
                <p className="text-white"><img className="inline-block mr-1" src={postgresql_logo} alt="postgresql-logo"></img>PostgreSQL</p>
            </div>
        </div>
        </div>
    );
}

export default Skills;