import { useState } from 'react';
import Hamburger from 'hamburger-react';

function Menu() {
    const [isOpen, setIsOpen] = useState(false);

    function toggleMenu() {
        setIsOpen(!isOpen);
    }
    return (
        <>
            <div className="bg-opacity-80 backdrop-blur-lg bg-secondary fixed top-0 left-0 right-0 p-2 flex border-b border-slate-600">
                <Hamburger size={30} color="#5fc2ba" toggled={isOpen} toggle={toggleMenu} />
                <h1 className="text-3xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-fourth to-blue-500 mt-2 mx-auto">RC</h1>
            </div>
            {isOpen && (
                <div className=" text-2xl w-48 h-full fixed backdrop-blur-lg bg-secondary border-t border-slate-600 p-1 top-16 bg-opacity-80"
                >
                    <nav className=" flex flex-col">
                        <a
                            href="#home"
                            className="py-2 text-fourth transition ease-in duration-100 delay-100 hover:text-white hover:drop-shadow-xl"
                            onClick={toggleMenu}
                        >
                            Home
                        </a>
                        <a
                            href="#skills"
                            className="py-2 text-fourth transition ease-in duration-100 delay-100 hover:text-white hover:drop-shadow-xl"
                            onClick={toggleMenu}
                        >
                            Skills
                        </a>
                        <a
                            href="#projects"
                            className="py-2 text-fourth transition ease-in duration-100 delay-100 hover:text-white hover:drop-shadow-xl"
                            onClick={toggleMenu}
                        >
                            Projects
                        </a>
                        <a
                            href="#contact"
                            className="py-2 text-fourth transition ease-in duration-100 delay-100  hover:text-white hover:drop-shadow-xl"
                            onClick={toggleMenu}
                        >
                            Contact
                        </a>
                    </nav>
                </div>
            )}
        </>
    );
}

export default Menu;
