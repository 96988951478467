import onsport_logo from "../../../assets/OnSport_logo.png";
import pokedex_logo from "../../../assets/pokedex.png";

function Projects() {

  return (
    <div id="projects" className="h-max p-20">
        
        <h2 className="text-4xl lg:text-7xl flex justify-center"><span className="text-4xl lg:text-7xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-fourth to-blue-500 ">Projects</span></h2>
        <>  
        <div class="max-w-xs rounded-t-xl overflow-auto shadow-lg mx-auto mt-10 border border-slate-600 bg-red-500">
                    <img className="w-2/4 mx-auto my-20 " src={pokedex_logo} alt="onsport logo" />
                </div>
                        <div class="max-w-xs  mx-auto px-2 py-2 text-white text-justify border-x border-b rounded-b-xl border-slate-600 bg-primary">
                    <a href="https://pokedex-six-dun.vercel.app" rel="noreferrer" target="_blank">
                        <h1 className="font-bold text-xl mb-2 text-center text-white underline decoration-solid hover:text-fourth">Pokédex</h1>
                    </a>
                            Pokédex Poject: A web application that explores the world of Pokémon. Built with Next.js and powered by PokeAPI, this platform provides information about each Pokémon. The site is fully responsive.
                        </div>
                <div class="max-w-xs rounded-t-xl overflow-auto shadow-lg mx-auto mt-10 border border-slate-600 bg-blue-700">
                    <img className="w-2/4 mx-auto" src={onsport_logo} alt="onsport logo" />
                </div>
                        <div class="max-w-xs  mx-auto px-2 py-2 text-white text-justify border-x border-b rounded-b-xl border-slate-600 bg-primary">
                    <a href="http://karenbartaud.fr/onsport" rel="noreferrer" target="_blank">
                        <h1 className="font-bold text-xl mb-2 text-center text-white underline decoration-solid hover:text-fourth">OnSport</h1>
                    </a>
                            Final training project: a community website for sharing sports experiences among athletes worldwide. Objective: facilitate the discovery of recommended sports activities in France during vacations.
                        </div>
            </>
                </div>
  );
}


export default Projects;

                