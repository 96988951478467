
import github_logo from "../../../assets/icons8-github.svg";
import linkedin_logo from "../../../assets/icons8-linkedin.svg";

function Home() {
    return (
        <div id="home" className="mt-20 pt-20 h-max">
            <div data-aos="fade-left" data-aos-duration="1000" className="flex flex-col justify-center rounded-lg shadow-lg w-96 lg:w-3/5 mx-auto p-5 border border-slate-600">
                <h1 data-aos="fade-right" data-aos-duration="2000" className="text-3xl lg:text-6xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-fourth to-blue-500">Hello!</h1>
                <h2 data-aos="fade-right" data-aos-duration="2000" className="text-3xl lg:text-5xl text-sky-50"> Welcome to my portfolio</h2>
                <h2 data-aos="fade-right" data-aos-duration="2000" className="text-4xl lg:text-7xl text-white">I'm <span className="text-4xl lg:text-7xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-fourth to-blue-500 ">Ramazan Cinar</span></h2>
                <h2 data-aos="fade-right" data-aos-duration="2000" className="text-3xl lg:text-5xl text-white">a  Full-stack developer</h2>
            </div>
            <div className="flex justify-center my-20">
                <a href="https://github.com/RamazanCinar" target="_blank" rel="noreferrer" className="hover:transform hover:scale-125 hover: duration-150">
                    <img src={github_logo} alt="github"/>
                </a>
                <a href="https://www.linkedin.com/in/ramazan-cinar/" target="_blank" rel="noreferrer" className="hover:transform hover:scale-125 hover: duration-150">
                    <img src={linkedin_logo} alt="linkedin"/>
                </a>
            </div>
            <div className=" text-white text-lg text-justify flex justify-center rounded-lg shadow-lg w-96 lg:w-2/5 mx-auto p-5 border border-slate-600 p">
                I specialize in creating dynamic and interactive websites using technologies such as React and Node.
                I am passionate about web development and I enjoy learning new technologies to improve my skills and stay up-to-date on the latest trends.
            </div>
                
        </div>
    );
}

export default Home;


