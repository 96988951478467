import github_logo from '../../../assets/icons8-github.svg';
import linkedin_logo from '../../../assets/icons8-linkedin.svg';
import cv from '../../../assets/CV-ramazan-cinar-en.pdf';

function Contact() {
    return(
        <>
        <div id="contact" className="h-max p-20">
            <div>
                <h2 className="text-4xl lg:text-7xl flex justify-center mb-10"><span className="text-4xl lg:text-7xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-fourth to-blue-500 ">Contact</span></h2>
            </div>
            <div className="flex justify-center mt-10">
                 <a href="https://www.linkedin.com/in/ramazan-cinar/" target="_blank" rel="noreferrer" className="hover:transform hover:scale-125 duration-150">
                    <img src={linkedin_logo} alt="linkedin"/>
                </a>
                <a href="https://github.com/RamazanCinar" target="_blank" rel="noreferrer" className="hover:transform hover:scale-125 duration-150">
                    <img src={github_logo} alt="github"/>
                </a>
                
            </div>

            <div className="flex justify-center">
                <button className=" w-60 h-16 bg-cyan-500 hover:bg-cyan-700 text-white text-xl font-bold py-2 px-4 rounded mt-10">
                    <a href={cv} download>Download my resume</a>
                </button>
            </div>

            

        </div>
        <div>
            <p className="text-sm text-fourth mt-20 mb-2 flex justify-center align-bottom">Designed and developed by <span className="font-semibold inline-block ml-1">Ramazan Cinar</span></p>
        </div>
        </>

    );
}

export default Contact;